<template>
  <div class="theExperience">
    <section>
      <article>
        <div class="container">
          <div class="articleOne d-flex align-items-end">
            <div class="row">
              <div class="col-md-8">
                <h2>Gorilla Trekking in Bwindi Impenetrable National Park.</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur odit
                  officia doloremque corporis reiciendis eum quo id aperiam magnam velit?
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur odit
                  officia doloremque corporis reiciendis eum quo id aperiam magnam velit?
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur odit
                  officia doloremque corporis reiciendis eum quo id aperiam magnam velit?
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur odit
                  officia doloremque corporis reiciendis eum quo id aperiam magnam velit?
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
    <!-- <section>
      <article>
        <div class="carousel_description">
          <div class="images">
            <div>
              <img
                src="https://images.pexels.com/photos/325260/pexels-photo-325260.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                class="d-block w-100"
                alt="..."
              />
            </div>
            <div>
              <div>
                <img
                  src="https://images.pexels.com/photos/7280783/pexels-photo-7280783.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
              <div>
                <img
                  src="https://images.pexels.com/photos/6746533/pexels-photo-6746533.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
              <div>
                <img
                  src="https://images.pexels.com/photos/667200/pexels-photo-667200.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
              <div>
                <img
                  src="https://images.pexels.com/photos/632452/pexels-photo-632452.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
            </div>
          </div>
          <div class="image-description">
            <div class="container">
              <div>
                <p class="locus_word_style">
                  <small>Ihamba Adventures</small>
                </p>
                <p>The Birders Experience.</p>
                <button
                  class="btn btn-md moreBtn"
                  @click="$router.push({path: '/safari-packages'})"
                >
                  Explore more
                  <i class="el-icon-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section> -->

    <section>
      <article>
        <div class="experience_grid mb-5">
          <div>
            <img
              src="https://images.pexels.com/photos/325260/pexels-photo-325260.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              class="d-block w-100"
              alt="..."
            />
            <div class="experience_description text-center">
              <h2 style="font-size: 1.2em; font-weight: 600;">Lorem ipsum dolor sit amet.</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur odit
                officia doloremque corporis reiciendis eum quo id aperiam magnam velit?
              </p>
            </div>
          </div>
          <div>
            <img
              src="https://images.pexels.com/photos/7280783/pexels-photo-7280783.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
              class="d-block w-100"
              alt="..."
            />
            <div class="experience_description text-center">
              <h2 style="font-size: 1.2em; font-weight: 600;">Lorem ipsum dolor sit amet.</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur odit
                officia doloremque corporis reiciendis eum quo id aperiam magnam velit?
              </p>
            </div>
          </div>
          <div>
            <img
              src="https://images.pexels.com/photos/6746533/pexels-photo-6746533.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
              class="d-block w-100"
              alt="..."
            />
            <div class="experience_description text-center">
              <h2 style="font-size: 1.2em; font-weight: 600;">Lorem ipsum dolor sit amet.</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur odit
                officia doloremque corporis reiciendis eum quo id aperiam magnam velit?
              </p>
            </div>
          </div>
          <div>
            <img
              src="https://images.pexels.com/photos/667200/pexels-photo-667200.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              class="d-block w-100"
              alt="..."
            />
            <div class="experience_description text-center">
              <h2 style="font-size: 1.2em; font-weight: 600;">Lorem ipsum dolor sit amet.</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur odit
                officia doloremque corporis reiciendis eum quo id aperiam magnam velit?
              </p>
            </div>
          </div>
          <div>
            <img
              src="https://images.pexels.com/photos/70080/elephant-africa-african-elephant-kenya-70080.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              class="d-block w-100"
              alt="..."
            />
            <div class="experience_description text-center">
              <h2 style="font-size: 1.2em; font-weight: 600;">Lorem ipsum dolor sit amet.</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur odit
                officia doloremque corporis reiciendis eum quo id aperiam magnam velit?
              </p>
            </div>
          </div>
          <div>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/The_Karamojong_or_Karimojong%2C_an_ethnic_group_of_agro-pastoral_herders_living_mainly_in_the_north-east_of_Uganda..jpg/1200px-The_Karamojong_or_Karimojong%2C_an_ethnic_group_of_agro-pastoral_herders_living_mainly_in_the_north-east_of_Uganda..jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="experience_description text-center">
              <h2 style="font-size: 1.2em; font-weight: 600;">Lorem ipsum dolor sit amet.</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur odit
                officia doloremque corporis reiciendis eum quo id aperiam magnam velit?
              </p>
            </div>
          </div>
        </div>
      </article>
    </section>

  </div>
</template>

<script>
export default {
  components: {},
};
</script>


<style scoped>
.theExperience {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    ),
    url("https://images.pexels.com/photos/6194629/pexels-photo-6194629.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.experience_grid {
  margin-top: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.experience_grid > div {
  height: 500px;
  position: relative;
  border-radius: 5px;
}

.experience_grid > div img {
  height: 500px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.experience_description {
  border-radius: 5px;
  width: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  padding: 50px 20px;
  background-image: linear-gradient(rgba(16, 50, 48, 1), rgba(16, 50, 48, 0));
  color: white;
}

.articleOne {
  height: 120vh;
}

.articleOne h2 {
  font-size: 3em;
  font-weight: 600;
  color: #233a2e;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

.articleOne p {
  color: #233a2e;
  font-size: 1.2em;
  font-weight: 600;
}

.locus_word_style {
  font-size: 0.5em;
  font-weight: 300;
  word-spacing: 8px;
  letter-spacing: 5px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.carousel_description {
  position: relative;
  height: 100vh;
}
.carousel_description .images {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.carousel_description .images > div:first-child {
  height: 100vh;
  width: 100%;
}

.carousel_description .images > div:first-child img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.carousel_description .images > div:nth-child(2) {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.carousel_description .images > div:nth-child(2) > div {
  height: 50vh;
  width: 100%;
}
.carousel_description .images > div:nth-child(2) > div img {
  height: 50vh;
  width: 100%;
  object-fit: cover;
}

.carousel_description .image-description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  /* margin-left:10%;
    margin-right:10%; */
  padding-bottom: 10%;
  background-image: linear-gradient(
    to bottom left,
    rgba(255, 255, 255, 0),
    rgba(61, 41, 10, 0.5)
  );
  display: flex;
  align-items: flex-end;
}

.carousel_description .image-description > div {
  /* background-color: green; */
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 600;
  color: white;
  text-align: center;
}

.moreBtn {
  background-color: transparent;
  color: #fbf2e1;
  border: 1px solid #fbf2e1;
  border-radius: 0px;
}

.moreBtn:hover {
  background-color: rgba(61, 41, 10, 0.5);
  color: #fbf2e1;
  border: 1px solid #fbf2e1;
  border-radius: 0px;
}

.theCarouselArticle {
  height: 100vh;
  /* background-color: #EEEEEE; */
  background-image: linear-gradient(to right, #f4dcb7, #fbf2e1, #f4dcb7);
  position: relative;
}

.availabilityForm {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.availabilityForm > div {
  font-size: 0.8em;
}

.checkBtn {
  background-color: #996727;
  border: 2px solid #996727;
  color: #fbf2e1;
  border-radius: 0px;
  text-transform: uppercase;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .experience_grid {
    margin-top: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .availabilityForm {
    display: block;
  }

  .checkBtn {
    width: 100%;
  }

  .carousel_description .image-description {
    padding-bottom: 55%;
  }

  .peopleNo {
    margin-top: 20px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .experience_grid {
    margin-top: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .articleOne {
    height: 100vh;
  }

  .articleOne h2 {
    font-size: 2em;
    font-weight: 600;
    color: #233a2e;
    text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
      0px -4px 10px rgba(255, 255, 255, 0.3);
  }

  .articleOne p {
    color: #233a2e;
    font-size: 1em;
    font-weight: 500;
  }

  .availabilityForm {
    display: block;
  }

  .checkBtn {
    width: 100%;
  }

  .carousel_description .image-description {
    padding-bottom: 55%;
  }
  .carousel_description .images {
    display: grid;
    grid-template-columns: 1fr;
  }
  .carousel_description .images > div:first-child {
    height: 50vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .carousel_description .images > div:nth-child(2) {
    height: 50vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .carousel_description .images > div:nth-child(2) > div {
    height: 25vh;
    width: 100%;
  }
  .carousel_description .images > div:nth-child(2) > div img {
    height: 25vh;
    width: 100%;
    object-fit: cover;
  }

  .peopleNo {
    margin-top: 20px;
  }
}
</style>

<style>
.el-carousel__button {
  display: block;
  opacity: 0.48;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
}
.input-class .el-input__inner {
  width: 50px !important;
}

.el-date-editor .el-range-input {
  /* width: 20% !important; */
}

.el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: 100% !important;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .el-date-editor--datetimerange.el-input,
  .el-date-editor--datetimerange.el-input__inner {
    margin-bottom: 20px;
  }

  .el-input-number.is-controls-right {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
